<template>
  <div class="c_change_address">
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }" >
      <van-picker
        title="银行卡列表"
        value-key="bank_name"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        :default-index="defaultIndex"
      />
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        defaultIndex: 0,
        show: false,
        //  '中国建设银行', '中国农业银行', '中国工商银行', '招商银行', '中国银行', '交通银行', '中国邮政储蓄银行', '民生银行', '广发银行', '平安银行', '中信银行'
        columns: []
          // { name: '中国建设银行'},
          // { name: '中国农业银行'},
          // { name: '中国工商银行'},
          // { name: '招商银行'},
          // { name: '中国银行'},
          // { name: '交通银行'},
          // { name: '中国邮政储蓄银行'},
          // { name: '民生银行'},
          // { name: '广发银行'},
          // { name: '平安银行'},
          // { name: '中信银行'}
      }
    },
    methods: {
      onShow(flag, list, bankname = '') {
        this.columns = list
        if(bankname) {
          let obj = list.find(item => item.bank_name = bankname)
          if(obj) this.defaultIndex = list.indexOf(obj)
        }
        this.show = flag
      },
      onConfirm(val) {
        this.$emit('emitBankPicker', val)
        this.show = false
      },
      onCancel() {
        this.show = false
      }
    }
  }
</script>

<style lang="scss">
.c_change_address {
  .van-popup {
    overflow: hidden;
  }
}
</style>